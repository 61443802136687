import React, {Component, useRef, useCallback} from 'react';
import styled from 'styled-components';
const appConfig = require('../../data.json');

const startAsHttpPattern = /^(http|https):\/\//;

function Image(props) {
  const {src, host, css, background} = props;

  const imgOnLoad = useCallback((e) => {}, []);

  const imgOnError = useCallback(
    (e) => {
      if (!src.match(startAsHttpPattern)) {
        console.log('on error!! try next host...');
      }
    },
    [src],
  );

  const getFullImageUrl = useCallback((src, host) => {
    if (!src) {
      return '';
    }
    if (src.match(startAsHttpPattern)) {
      return src;
    } else if (src.indexOf('/') === 0) {
      return `${host}${src}`;
    } else if (src.indexOf('.') === 0) {
      return src;
    } else {
      return `${host}${src}`;
    }
  }, []);

  if (background) {
    return (
      <Background
        css={css}
        onLoad={imgOnLoad}
        onError={imgOnError}
        {...props}
        src={getFullImageUrl(src, host)}
      />
    );
  } else {
    return (
      <Img
        css={css}
        onLoad={imgOnLoad}
        onError={imgOnError}
        {...props}
        src={getFullImageUrl(src, host)}
      />
    );
  }
}

Image.defaultProps = {
  host: appConfig.endpoint.mediaHost,
  background: false,
  css: '',
};

const Img = styled.img`
  ${(props) => props.css}
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  ${(props) => props.css}
`;

export default Image;
