import React from 'react';
import styled from 'styled-components';
import {Layout, Menu} from 'antd';
import * as Ant from 'antd';

const {Header: AntHeader, Content, Sider} = Layout;
const {SubMenu} = Menu;

function traceEntry({location, entries}) {
  let name = location.pathname.split('/')[1] || 'welcome';

  for (let e of entries) {
    if (e.name === name) {
      return {entry: e};
    }
    if (e.entries && e.entries.length > 0) {
      let {entry} = traceEntry({location, entries: e.entries});
      if (entry) {
        return {entry: e, child: entry};
      }
    }
  }
  return {entry: {name: '', label: ''}};
}

class CmsDashboard extends React.Component {
  static defaultProps = {
    Header: 'Revtel Admin',
    entryFunctionHandler: () => 0,
    checkPermission: () => 0,
  };

  componentDidMount() {
    let {
      checkPermission,
      location: {pathname},
    } = this.props;
    const trimmedPathname = pathname.replace(/^\/|\/$/g, '').split('/')[0];
    checkPermission(`${trimmedPathname}/`);
  }

  constructor(props) {
    super(props);

    let {entry, child} = traceEntry({
      location: props.location,
      entries: props.entries,
    });

    this.state = {
      length: this.props.entries.length,
      selectedEntry: child || entry,
      selectedParentEntry: entry,
      rwdSidebarOpen: false,
      collapsed: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.entries.length !== prevState.length) {
      return {
        length: nextProps.entries.length,
        selectedEntry: nextProps.entries[0],
      };
    }
    return null;
  }

  render() {
    let {entries, loginProfile} = this.props;
    let {selectedEntry} = this.state;

    return (
      <Layout style={{minHeight: '100vh'}}>
        <Sider
          collapsible
          collapsed={this.state.collapsed}
          onCollapse={(collapsed) => this.setState({collapsed})}>
          {this._renderHeader()}
          <Menu
            theme="dark"
            defaultSelectedKeys={[selectedEntry.name]}
            mode="inline">
            {entries.map((entry) => {
              if (entry.entries && entry.entries.length > 0) {
                return (
                  <SubMenu key={entry.name} title={<span>{entry.label}</span>}>
                    {entry.entries.map((e) => (
                      <Menu.Item
                        key={e.name}
                        onClick={() =>
                          this._onSelectEntry({entry: e, parentEntry: entry})
                        }>
                        <span>{e.label}</span>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                );
              }

              return (
                <Menu.Item
                  key={entry.name}
                  onClick={() => this._onSelectEntry({entry})}>
                  {entry.icon || null}
                  <span>{entry.label}</span>
                </Menu.Item>
              );
            })}
          </Menu>
        </Sider>
        <Layout>
          <AntHeader style={{backgroundColor: 'white', padding: '0 15px'}}>
            <Ant.Row justify="end">
              <Ant.Col flex="auto">{this._renderNavLink()}</Ant.Col>
              <Ant.Col span={6}>
                <div style={{marginLeft: 10, textAlign: 'right'}}>
                  {loginProfile.user.username}
                  {loginProfile.staff_type === 'staff' && ' - 員工'}
                </div>
              </Ant.Col>
            </Ant.Row>
          </AntHeader>
          <Content>{this._renderContent()}</Content>
        </Layout>
      </Layout>
    );
  }

  _renderNavLink = () => {
    const {selectedParentEntry, selectedEntry} = this.state;
    let output = selectedEntry.label;
    if (selectedParentEntry) {
      output = selectedParentEntry.label + ' / ' + output;
    }

    return <h2 style={{letterSpacing: 2}}>{output}</h2>;
  };

  _renderHeader = () => {
    let {Header} = this.props;
    let {selectedEntry} = this.state;

    if (typeof Header === 'string') {
      return <div className="site-title">Revtel Admin</div>;
    } else if (typeof Header === 'function') {
      // Component
      return <Header selectedEntry={selectedEntry} />;
    } else if (typeof Header === 'object') {
      // Element
      return Header;
    }
  };

  _renderContent = () => {
    let {selectedEntry} = this.state;
    try {
      return this.props.children({
        cms: {
          entry: selectedEntry,
        },
      });
    } catch (ex) {
      return <div>{selectedEntry.name}</div>;
    }
  };

  _onSelectEntry = ({entry, parentEntry}) => {
    let {entryFunctionHandler, checkPermission} = this.props;
    entryFunctionHandler(entry);
    checkPermission(`${entry.name}/`);
    this.setState({
      selectedEntry: entry,
      selectedParentEntry: parentEntry,
      rwdSidebarOpen: false,
    });
    // if (entry.type === "function") {

    // } else {

    // }
  };

  _toggleRwdSidebar = () => {
    let {rwdSidebarOpen} = this.state;
    this.setState({rwdSidebarOpen: !rwdSidebarOpen});
  };
}

const Avatar = styled.div`
  border-radius: 20px;
  width: 40px;
  height: 40px;
  background-color: #aaa;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
`;

export default CmsDashboard;
