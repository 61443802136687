import api from './ApiProxy.js';
import decodeJWTPayload from './Utils/JwtDecode.js';
import {
  getTokenFromLocalStorage,
  setTokenInLocalStorage,
  removeTokenFromLocalStorage,
} from './Utils/LocalStorageUtil';
import {navigate} from 'gatsby';

const API_PREFIX = '/api/v1/admin';
let accessToken = getTokenFromLocalStorage('accessToken');
let refreshToken = getTokenFromLocalStorage('refreshToken');
let accessTokenExpiry = getTokenFromLocalStorage('accessTokenExpiry');
let refreshTokenExpiry = getTokenFromLocalStorage('refreshTokenExpiry');
let refreshingPromise = null;

function isTokenExpired(tokenExpiry) {
  if (!tokenExpiry) {
    return true;
  }
  const currentTimestamp = Math.floor(Date.now() / 1000);
  return currentTimestamp >= Number(tokenExpiry);
}

function isAccessTokenExpired(exp = accessTokenExpiry) {
  return isTokenExpired(exp);
}

function isRefreshTokenExpired() {
  return isTokenExpired(refreshTokenExpiry);
}

async function updateToken() {
  if (refreshingPromise) {
    return refreshingPromise;
  }

  if (refreshToken && !isRefreshTokenExpired()) {
    refreshingPromise = api
      .post({
        path: `${API_PREFIX}/token/refresh`,
        data: {refresh: refreshToken},
        secure: false,
      })
      .then(({access, refresh}) => {
        const {exp: accessTokenExp} = decodeJWTPayload(access);
        const {exp: refreshTokenExp} = decodeJWTPayload(refresh);
        refreshingPromise = null;
        accessToken = access;
        refreshToken = refresh;
        accessTokenExpiry = accessTokenExp;
        refreshTokenExpiry = refreshTokenExp;

        setTokenInLocalStorage('accessToken', access);
        setTokenInLocalStorage('refreshToken', refresh);
        setTokenInLocalStorage('accessTokenExpiry', accessTokenExpiry);
        setTokenInLocalStorage('refreshTokenExpiry', refreshTokenExpiry);
        return access;
      })
      .catch((err) => {
        refreshingPromise = null;
        if (err.status === 401) {
          navigate('/logout');
        }
        throw err;
      });

    return refreshingPromise;
  } else {
    throw new Error('Refresh token is expired or not available');
  }
}

async function getAccessToken() {
  if (!accessToken || !refreshToken) {
    return null;
  }
  if (isAccessTokenExpired()) {
    return updateToken();
  }
  return accessToken;
}

export {getAccessToken, isAccessTokenExpired};
