import React, {useState} from 'react';
import styled from 'styled-components';
import Image from './Image';
const appConfig = require('../data.json');
import {useOutlet} from 'reconnect.js';
import {message, Form} from 'antd';

export default function LoginForm({}) {
  const [values, setValues] = useState({
    username: '',
    password: '',
  });
  const [autoLoginProcessing] = useOutlet('autoLoginProcessing');
  const [actions] = useOutlet('actions');

  const login = async () => {
    let {username, password} = values;
    if (!username || !password) {
      return;
    }
    actions.setLoading(true);
    try {
      await actions.login({username, password});
    } catch (ex) {
      message.error('帳號密碼錯誤');
    }
    actions.setLoading(false);
  };

  return (
    <Wrapper>
      <div className="left-section">
        <img src="/images/logo.png" style={{width: '60%', height: 'auto'}} />
        <div
          style={{
            opacity: autoLoginProcessing ? 1 : 0,
            width: 300,
            margin: '40px 0',
          }}>
          <div className="line-spinner" />
        </div>
      </div>
      <div className={`right-section ${!autoLoginProcessing && 'expand'}`}>
        <img
          src="/images/hi.svg"
          width="150"
          height="150"
          style={{margin: 20}}
        />
        <Form
          onFinish={login}
          onFinishFailed={() => console.log('onFinishFailed')}
          className="login-form">
          <input
            placeholder="帳號"
            name="username"
            value={values.username}
            onChange={(e) =>
              setValues((prev) => ({
                ...prev,
                username: e.target.value,
              }))
            }
          />

          <input
            placeholder="密碼"
            name="password"
            type="password"
            value={values.password}
            onChange={(e) =>
              setValues((prev) => ({
                ...prev,
                password: e.target.value,
              }))
            }
          />
          <button className="login-button" htmltype="submit">
            登入
          </button>
        </Form>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  height: 100vh;

  @keyframes expand {
    0% {
      flex: 0;
    }

    100% {
      flex: 1;
    }
  }

  @keyframes spinner {
    0% {
      width: 0px;
      transform: translateX(300px);
    }

    50% {
      width: 300px;
      transform: translateX(0);
    }

    100% {
      width: 0;
      transform: translateX(0);
    }
  }

  & > .left-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${'#001529'};

    & > .wording {
      width: 70%;
      font-size: 38px;
      font-weight: bold;
      color: white;
      line-height: 73px;
    }

    & .line-spinner {
      background-color: white;
      height: 3px;
      width: 300px;
      animation: spinner 2s ease-in-out infinite;
    }
  }

  & > .right-section {
    flex: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    & > .login-form {
      display: flex;
      margin-bottom: 150px;
      align-items: center;
      flex-direction: column;

      input {
        width: 280px;
        margin: 10px;
        border-radius: 6px;
        outline: none;
        border: 1px solid #95a7b1;
        padding: 7px 12px;
      }
    }

    & .login-button {
      border: 0;
      margin-top: 10px;
      text-align: center;
      font-size: 16px;
      padding: 8px;
      width: 280px;
      color: white;
      background-color: ${appConfig.colors.main};
      cursor: pointer;
      border-radius: 6px;
    }
  }

  & > .expand {
    animation: expand 1s ease-in-out;
    flex: 1;
  }

  & .made-by {
    display: flex;
    white-space: nowrap;
    align-items: flex-end;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: rgba(190, 190, 190, 0.7);
    letter-spacing: 1.6px;

    @media screen and (max-width: 600px) {
      margin: 10px;
      font-size: 10px;
      bottom: -40px;
      white-space: unset;
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;
