import 'whatwg-fetch';
import nodeFetch from 'node-fetch';
import appConfig from './data.json';
import {getAccessToken, isAccessTokenExpired} from './TokenService';

const universalFetch = (function getCorrectFetchImpl() {
  try {
    return fetch;
  } catch (ex) {
    return nodeFetch;
  }
})();

function addTailingSlashForPath(url) {
  let [path, query] = url.split('?');
  return `${path}${query ? '?' + query : ''}`;
}

class _ApiProxy {
  constructor() {
    this._commonHeader = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    this.apiToken = null;
    this.apiTokenExpiry = null;
  }

  _buildEndpoint({path, withHost = false}) {
    if (withHost) {
      return path;
    }

    path = addTailingSlashForPath(path);
    return path ? `${appConfig.endpoint.apiUrl}${path}` : null;
  }

  _buildHeaders({
    extraHeaders,
    skipCommonHeader = false,
    secure = true,
    accessToken = null,
  }) {
    let authHeader =
      accessToken && secure ? {Authorization: `Bearer ${accessToken}`} : {};
    if (skipCommonHeader) {
      return {
        ...authHeader,
        ...extraHeaders,
      };
    }
    return {
      ...this._commonHeader,
      ...authHeader,
      ...extraHeaders,
    };
  }

  async _fetch({method, path, data, extraHeaders, withHost, secure}) {
    const endpoint = this._buildEndpoint({path, withHost});
    let accessToken = this.apiToken;
    if (
      secure &&
      (!accessToken || isAccessTokenExpired(this.accessTokenExpiry))
    ) {
      accessToken = await getAccessToken();
    }
    const headers = this._buildHeaders({extraHeaders, secure, accessToken});
    const options = {method, headers};

    if (data && (method === 'POST' || method === 'PUT')) {
      options.body = JSON.stringify(data);
    }

    let response = await universalFetch(endpoint, options);

    if (response.status === 204) {
      return null;
    }

    if (response.ok) {
      return response.json();
    }

    let error = await response.json();
    return Promise.reject({...error, status: response.status});
  }

  setToken(token, expiry = null) {
    this.apiToken = token;
    this.accessTokenExpiry = expiry;
  }

  async get({path, extraHeaders = {}, withHost = false, secure = true}) {
    return this._fetch({method: 'GET', path, extraHeaders, withHost, secure});
  }

  async post({path, extraHeaders = {}, data, withHost = false, secure = true}) {
    return this._fetch({
      method: 'POST',
      path,
      data,
      extraHeaders,
      withHost,
      secure,
    });
  }

  async put({path, extraHeaders = {}, data, withHost = false, secure = true}) {
    return this._fetch({
      method: 'PUT',
      path,
      data,
      extraHeaders,
      withHost,
      secure,
    });
  }

  async delete({path, extraHeaders = {}, withHost = false, secure = true}) {
    return this._fetch({
      method: 'DELETE',
      path,
      extraHeaders,
      withHost,
      secure,
    });
  }

  async formPost({
    path,
    extraHeaders = {},
    formData,
    withHost = false,
    secure = true,
  }) {
    const endpoint = this._buildEndpoint({path, withHost});
    let accessToken = this.apiToken;
    if (
      secure &&
      (!accessToken || isAccessTokenExpired(this.accessTokenExpiry))
    ) {
      accessToken = await getAccessToken();
    }
    const headers = this._buildHeaders({
      extraHeaders,
      skipCommonHeader: true,
      secure,
      accessToken,
    });

    return universalFetch(endpoint, {
      method: 'POST',
      headers,
      body: formData,
    }).then((response) => {
      if (response.status === 204) {
        return null;
      }

      if (`${response.status}`[0] === '2') {
        return response.json();
      }
      return Promise.reject({status: response.status});
    });
  }

  async formPut({
    path,
    extraHeaders = {},
    formData,
    withHost = false,
    secure = true,
  }) {
    const endpoint = this._buildEndpoint({path, withHost});
    let accessToken = this.apiToken;
    if (
      secure &&
      (!accessToken || isAccessTokenExpired(this.accessTokenExpiry))
    ) {
      accessToken = await getAccessToken();
    }
    const headers = this._buildHeaders({
      extraHeaders,
      skipCommonHeader: true,
      secure,
      accessToken,
    });

    return universalFetch(endpoint, {
      method: 'PUT',
      headers,
      body: formData,
    }).then((response) => {
      if (response.status === 204) {
        return null;
      }

      if (`${response.status}`[0] === '2') {
        return response.json();
      }
      return Promise.reject({status: response.status});
    });
  }

  getImage(imageUrl) {
    return this._buildEndpoint({path: imageUrl});
  }
}

export default new _ApiProxy();
