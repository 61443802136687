/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import {AppRoot, PageContainer} from './src/PageContainer';
import {globalHistory} from '@reach/router';

export const wrapPageElement = ({element, props}) => {
  return <PageContainer {...props}>{element}</PageContainer>;
};

export const wrapRootElement = ({element}) => {
  return <AppRoot>{element}</AppRoot>;
};

export const onInitialClientRender = () => {
  /**
   * This is a workaround for a bug in Gatsby
   *
   * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
   */
  globalHistory._onTransitionComplete();
};
