import React from 'react';
import styled from 'styled-components';
import {Spinner2} from '@styled-icons/evil/Spinner2';
import {useOutlet} from 'reconnect.js';

export default function Rotate({color}) {
  const [loading] = useOutlet('loading');

  const visible = loading;

  return (
    <>
      <Backdrop visible={visible} />

      {visible && (
        <Wrapper visible={visible}>
          <div className="spinner">
            <Spinner2 size={32} color={color} />
          </div>
        </Wrapper>
      )}
    </>
  );
}

const CoverAll = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  z-index: 110;
`;

const Wrapper = styled(CoverAll)`
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  @keyframes infinite-spinning {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(360deg) scale(1.2);
    }
    100% {
      transform: rotate(720deg) scale(1);
    }
  }

  & > .spinner {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    transition: 200ms;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    animation: infinite-spinning 1.2s infinite;
    box-shadow: 0 0 10px -5px #000;
  }
`;

const Backdrop = styled(CoverAll)`
  z-index: 1100;
  transition: 200ms;
  background-color: ${(props) => (props.visible ? '#5555' : 'transparent')};
`;
