import {message} from 'antd';

class ErrBase extends Error {}
class ErrOrder extends ErrBase {}
class ErrSpec extends ErrBase {}
class ErrOrderItem extends ErrBase {}
class ErrPromotion extends ErrBase {}
class ErrReport extends ErrBase {}
class ErrRefund extends ErrBase {}
class ErrMember extends ErrBase {}
class ErrUpgrade extends ErrBase {}
class ErrPromoItem extends ErrBase {}
class ErrInvoice extends ErrBase {}
class ErrProduct extends ErrBase {}
class ErrLogin extends ErrBase {}
class ErrCustomOrder extends ErrBase {}
class ErrAdmin extends ErrBase {}
class ErrHistory extends ErrBase {}

class ErrJsonSchema {
  constructor(loc) {
    let [property, field, position, optionProperty] = loc;
    this.message = `${property}的${
      ['options', 'terms'].includes(field) ? '選項' : field
    }，第${position}個項目的${optionProperty}欄位，有錯誤。`;
  }
}

function jsonSchemaErrorHandler(ex) {
  let errors = [];

  let {detail} = ex;
  if (Array.isArray(detail)) {
    errors = detail.map((d) => new ErrJsonSchema(d.loc));
  }
  return errors;
}

function errorHandler(ex, defaultMessage = '發生錯誤') {
  console.warn(ex);
  if (ex instanceof ErrBase) {
    message.warning(ex.message);
  } else if (ex.status === 403 || ex.error === 'permission_denied') {
    message.error('沒有權限或憑證過期，請再嘗試一次');
  } else {
    message.error(defaultMessage);
  }
}

export {
  ErrOrder,
  ErrSpec,
  ErrOrderItem,
  ErrPromotion,
  ErrReport,
  ErrRefund,
  ErrMember,
  ErrUpgrade,
  ErrPromoItem,
  ErrInvoice,
  ErrProduct,
  ErrLogin,
  ErrCustomOrder,
  ErrAdmin,
  ErrHistory,
  errorHandler,
  jsonSchemaErrorHandler,
};
