import React, {useCallback, useEffect} from 'react';
import styled from 'styled-components';
import LoginForm from './Components/LoginForm';
import CmsDashboard from './Components/Dashboard';
import {navigate} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
} from '@react-pdf/renderer';
const appConfig = require('./data.json');

Font.register({
  family: 'MiSans',
  src: '/MiSans-Normal.ttf',
});

Font.registerHyphenationCallback((word) => {
  if (word.length === 1) {
    return [word];
  }

  return Array.from(word)
    .map((char) => [char, ''])
    .reduce((arr, current) => {
      arr.push(...current);
      return arr;
    }, []);
});

const lagacyEntries = [
  'welcome',
  // 'orderList',
  // 'review',
  // 'production',
  // "logistic",
  // "normalInvoice",
  // "manualInvoice",
  // "normal",
  // "enterprise",
  // "reqUpgrade",
  // 'supplier',
  // 'return',
  // 'refund',
];

export default function Layout({children, location}) {
  const [_profile, setProfile] = useOutlet('user');
  const [actions] = useOutlet('actions');
  const [profile] = useOutlet('user');
  const [sidebar] = useOutlet('sidebar');

  const onEntryFunction = useCallback(async (entry) => {
    if (entry.name === 'logout') {
      await actions.logout();
      actions.autoLoginFinish();
      setProfile(null);
      actions.setToken(null);
      return;
    }
    if (lagacyEntries.includes(entry.name)) {
      navigate(`/`);
    } else {
      navigate(`/${entry.name}/`);
    }
  }, []);

  const onCheckPermission = useCallback(async (path) => {
    if (!path || path === 'logout/') {
      return;
    }
    actions.checkUserPermission(path);
  }, []);

  if (!profile) {
    return <LoginForm />;
  } else {
    return (
      <Wrapper>
        <CmsDashboard
          loginProfile={profile}
          themeColor={appConfig.colors.main}
          Header={
            <CustomHeader>
              <img
                src="/images/logo.png"
                width="100%"
                height="auto"
                style={{padding: 10}}
              />
            </CustomHeader>
          }
          location={location}
          entries={sidebar || []}
          entryFunctionHandler={onEntryFunction}
          checkPermission={onCheckPermission}>
          {({cms}) => {
            if (lagacyEntries.includes(cms.entry.name)) {
              return React.cloneElement(children, {cms});
            } else {
              return children;
            }
          }}
        </CmsDashboard>
      </Wrapper>
    );
  }
}

const CustomHeader = styled.div`
  background-color: #002140;
  padding: 12px 8px;
  min-height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > h3 {
    color: white;
  }
`;

const Wrapper = styled.div`
  & .ant-input,
  .ant-picker,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 6px;
  }
  &
    .ant-input-search
    .ant-input-group
    .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 6px 0 0 6px;
  }
  &
    .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 0 6px 6px 0;
  }

  & .ant-pagination-item,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-radius: 8px;
  }

  & .ant-table-tbody tr.production-order-expand > td {
    padding: 0;
  }
`;
