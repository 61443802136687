function decodeJWTPayload(token) {
  const parts = token.split('.');
  if (parts.length !== 3) {
    throw new Error('Invalid JWT Token');
  }

  const payload = parts[1];
  const decodedPayload = atob(payload);

  try {
    const parsedPayload = JSON.parse(decodedPayload);
    return parsedPayload;
  } catch (e) {
    throw new Error('Invalid payload encoding');
  }
}

export default decodeJWTPayload;
