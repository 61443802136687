function getTokenFromLocalStorage(key) {
  return typeof window !== 'undefined'
    ? window?.localStorage?.getItem(key) || null
    : null;
}

function setTokenInLocalStorage(key, value) {
  console.log(key);
  if (typeof window !== 'undefined') {
    window?.localStorage?.setItem(key, value);
  }
}

function removeTokenFromLocalStorage(key) {
  if (typeof window !== 'undefined') {
    window?.localStorage?.removeItem(key);
  }
}

export {
  getTokenFromLocalStorage,
  setTokenInLocalStorage,
  removeTokenFromLocalStorage,
};
